import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Avatar, Box, Card, Container, Divider, Grid, List, Stack, styled, Tooltip, Typography } from '@mui/material';
import MimavevaPage from '../MimavevaPage';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../services/userService';
import { IUser } from '../../entities';

import styles from 'styles/modules/app.module.scss';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import moment from 'moment';

import CelebrationIcon from '@mui/icons-material/Celebration';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const BirthdayWidget: React.FC = () => {
    const { t }: { t: any } = useTranslation();
    const [users, setUsers] = React.useState<IUser[]>([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);

        var userService = new UserService();
        userService.getUsers().then(data => {
            if (data == null) {
                return;
            }
            var sortedUsers = data.filter(d => d.dateOfBirth != null).sort((a, b) => moment(a.dateOfBirth).diff(moment(b.dateOfBirth)));;
            sortedUsers = sortedUsers.filter(d => (d.dateOfBirth != null) && isWithinTerm(d.dateOfBirth.toString()));

            for (var i = 0; i < 2; i++) {
                //sortedUsers = sortedUsers.concat(sortedUsers);
            }
            setUsers(sortedUsers);
        });

    }, [])

    const isWithinTerm = (dateString: string) => {
        var dateFormat = '____-MM-DD', // Ignore year, defaults to current year
            begin = moment().isoWeekday(0), // Subtract one day from start of term
            end = moment().isoWeekday(7), // Add one day to finish of term
            mom = moment(dateString, dateFormat); // Store to avoid re-compute below
        return mom.isBetween(begin, end) || mom.add(1, 'y').isBetween(begin, end);
    }

    return (<MimavevaPage>{users.length > 0 && (
        <Box sx={{ mb: "35px" }}>
            <Card variant="outlined">
                <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box display="flex" alignItems="center">
                        <AvatarPrimary sx={{ mr: 1.5 }}>
                            <CelebrationIcon />
                        </AvatarPrimary>
                        <Typography variant="h4">VERJAARDAGEN DEZE WEEK</Typography>
                    </Box>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <List sx={{ py: 0, display: "flex", flexWrap: "wrap", alignContent: "center" }}>
                        {users.map(user => (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginBottom: '10px', padding: "10px" }}
                            >
                                <Tooltip placement="top" title={t('View profile')} arrow>
                                    <Avatar
                                        component="a"
                                        href="#"
                                        sx={{
                                            mb: 1,
                                            width: 42,
                                            height: 42
                                        }}
                                        src={user.avatar}
                                    />
                                </Tooltip>
                                <Typography variant="h4">{user.username}</Typography>
                                <Typography variant="subtitle2" textAlign="center" noWrap>
                                    {moment(user.dateOfBirth).format('LL')}
                                </Typography>
                            </Box>
                        ))}
                    </List>
                    {users.length <= 0 && (
                        <Typography sx={{ p: 5 }}
                            variant="h3"
                            fontWeight="normal"
                            color="text.secondary"
                            align="center"
                        >

                            {"Geen verjaardagen deze week"}
                        </Typography>
                    )}
                </Grid>
            </Card>
        </Box>

    )}      </MimavevaPage>);
}

export default BirthdayWidget;