import * as React from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { Grid, Box, styled, Button, Card, Stack, Chip, Container, Avatar } from '@mui/material';
import { IMembership, IMatch, IMatchEnrollment, IUser, ITeam } from 'entities';
import { Events, EventEnrollments } from 'components/Events';
import PageTitleWrapper from '../PageTitleWrapper';
import PageHeader from './PageHeader';
import TopscorersList from '../Topscorers/TopscorersList';
import MatchAvailability from './MatchAvailability';
import SponsorsCard from './SponsorsCard'

// IMAGES
import BannerBackgroundImage from '../../images/dashboard-banner-bg.jpg';

// ICONS
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../contexts/AuthContext';
import { ImagePath } from '../../images';
import BirthdayWidget from '../Birthday/BirthdayWidget';
import { useNavigate } from 'react-router';

const CustomPageTitleWrapper = styled(PageTitleWrapper)(
    ({ theme }) => `
background-color: red;
    background-image: url(${BannerBackgroundImage})!important;
    background-size: cover;
    margin-bottom: 36px;
`
);

interface IProps {
    user: IUser;
    membership: IMembership;
    matches: IMatch[];
    isLoading?: boolean;
}

const DashboardStatistics: React.FC<IProps> = (props) => {
    const { isLoadingAuth } = useAuth();
    const navigate = useNavigate();

    const [matches, setMatches] = React.useState<IMatch[]>([]);
    const [selectedMatch, setSelectedMatch] = React.useState<IMatch | null>(null);
    const [upcomingMatch, setUpcommingMatch] = React.useState<IMatch | null>(null);
    const [previousMatches, setPreviousMatches] = React.useState<IMatch[] | null>(null);
    const [matchEnrollments, setMatchEnrollments] = React.useState<IMatchEnrollment[]>([]);
    const [latestModifiedDate, setLatestModifiedDate] = React.useState<Date | null>(null);
    const [isDetailsVisible, setIsDetailsVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.matches == null || props.matches.length <= 0) {
            return;
        }
        const now = moment();
        let filteredMatches = [...props.matches].sort((a, b) =>
            new Date(a.matchDate).getTime() - new Date(b.matchDate).getTime()
        ).filter(m => moment(m.matchDate).isSameOrAfter(now, 'days'));

        const sortedMatches = [...filteredMatches].sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate)));
        setMatches(sortedMatches);

        const sortedPrevMatches = [...props.matches].sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate))).reverse();
        setPreviousMatches(sortedPrevMatches.filter(item => !sortedMatches.includes(item)).slice(0, 4));

        // Temporary get first available match. Filter on date.
        const nextMatch = sortedMatches.filter(m => moment(m.matchDate).isSameOrAfter(now, 'days'))[0];
        if (nextMatch == null) {
            return;
        }
        setUpcommingMatch(nextMatch);
        const latestEnrollment = nextMatch.enrollments.length > 0 ? nextMatch.enrollments.reduce((a, b) => {
            return new Date(a.modifiedDate) > new Date(b.modifiedDate) ? a : b;
        }) : null;

        setLatestModifiedDate(latestEnrollment ? latestEnrollment.modifiedDate : null);
        setMatchEnrollments(nextMatch.enrollments.filter(e => e.status === "Aanwezig" && e.membership.type === "Speler"));
    }, [props.matches])

    const updateEnrollments = (match: IMatch) => {
        if (upcomingMatch != null && upcomingMatch.id !== match.id) {
            return;
        }
        setUpcommingMatch(match);
        const latestEnrollment = match.enrollments.length > 0 ? match.enrollments.reduce((a, b) => {
            return new Date(a.modifiedDate) > new Date(b.modifiedDate) ? a : b;
        }) : null;
        setLatestModifiedDate(latestEnrollment ? latestEnrollment.modifiedDate : null);
        setMatchEnrollments(match.enrollments.filter(e => e.status == "Aanwezig" && e.membership.type === "Speler"));
    }

    const updateMatch = (match: IMatch) => {
        const updatedMatchArray = matches.map(m => m.id === match.id ? { ...m, match } : m);
        setMatches(updatedMatchArray);
        updateEnrollments(match);
    }

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 30, height: 30, display: 'inline-block;', verticalAlign: 'middle' }}
        />
        </>);
    }

    const renderMatchChip = (match: IMatch) => {
        return (
            <Box sx={{}}>
                <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '50px !important' }}>
                        {renderTeam(match.team)}
                    </Grid>
                    <Grid item xs={12} md="auto" sx={{ display: 'flex', alignSelf: 'center', flexFlow: 'column', alignItems: 'center', width: '20px !important' }}>
                        <b>{match.teamGoals} - {match.opponentTeamGoals}</b>
                    </Grid>
                    <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '50px !important' }}>
                        {renderTeam(match.opponentTeam)}
                    </Grid>
                </Grid>
            </Box>);
    }

    const renderMatchResultChip = (match: IMatch) => {

        let status: 'WIN' | 'LOSE' | 'DRAW' | 'UNKOWN' = "UNKOWN";

        // Home match
        if (match.team.id === props.membership.team.id) {
            if (match.teamGoals > match.opponentTeamGoals) {
                status = "WIN";
            } else if (match.teamGoals < match.opponentTeamGoals) {
                status = "LOSE";
            } else if (match.teamGoals === match.opponentTeamGoals) {
                status = "DRAW";
            }
        }

        // Away match
        if (match.opponentTeam.id === props.membership.team.id) {
            if (match.teamGoals < match.opponentTeamGoals) {
                status = "WIN";
            } else if (match.teamGoals > match.opponentTeamGoals) {
                status = "LOSE";
            } else if (match.teamGoals === match.opponentTeamGoals) {
                status = "DRAW";
            }
        }

        switch (status) {
            case 'WIN':
                return <Chip label="W" color="success" size="small" />
            case 'LOSE':
                return <Chip label="L" color="error" size="small" />
            case 'DRAW':
                return <Chip label="D" size="small" />
            default:
                return <Chip label="X" size="small" />
        }
    }

    const isLoading = isLoadingAuth || props.isLoading;
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <CustomPageTitleWrapper>
                <PageHeader clubName={props.membership.team.name} />
            </CustomPageTitleWrapper>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                {(isDetailsVisible && selectedMatch != null) && (<>
                    <Grid item xs={6} md={6}>
                        <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 2 }}
                            variant="contained"
                            endIcon={<KeyboardReturnIcon />}
                            onClick={() => setIsDetailsVisible(false)}
                        >Terug
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        {props.user.role === "Admin" && (
                            <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 2, float: 'right' }}
                                variant="outlined"
                                onClick={() => navigate(`/manage/match/${selectedMatch.id}/details`)}
                                startIcon={<EditIcon />}>
                                Wijzig
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ paddingTop: '0!important' }}>
                        <EventEnrollments match={selectedMatch} enrollments={selectedMatch.enrollments} />
                    </Grid>
                </>) || (<>
                    {(previousMatches != null && previousMatches.length > 0) && (
                        <Grid item xs={12} md={12}>
                            <Card variant="outlined" sx={{ padding: '10px' }}>
                                <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3>{props.membership.team.name}</h3>
                                    <Stack spacing={1} alignItems="center" sx={{ justifyContent: 'space-evenly' }}>
                                        <Stack direction="row" spacing={1}>
                                            {previousMatches.map(match => (
                                                renderMatchResultChip(match)
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Container>
                                <Stack direction="row" spacing={1} sx={{
                                    overflowX: 'auto',
                                    padding: '10px',
                                    "::-webkit-scrollbar": {
                                        display: 'none'
                                    }
                                }}>
                                    {previousMatches.map(match => (
                                        <Chip key={match.id} sx={{
                                            height: '40px',
                                            "& .MuiChip-label": {
                                                padding: 0
                                            }
                                        }} label={renderMatchChip(match)} variant="outlined" />

                                    ))}
                                </Stack>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <BirthdayWidget />
                        {upcomingMatch != null && (
                            <MatchAvailability isLoading={isLoading} match={upcomingMatch}
                                modifiedDate={latestModifiedDate}
                                attendingCount={matchEnrollments.length}
                                totalEnrollments={upcomingMatch?.enrollments.filter(e => e.membership.type === "Speler").length || 0}
                                showMatchDetails={() => {
                                    setSelectedMatch(upcomingMatch);
                                    setIsDetailsVisible(true);
                                }} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ paddingBottom: 2 }}>
                            <Events title="AANKOMENDE WEDSTRIJDEN"
                                membership={props.membership}
                                isLoading={isLoading}
                                userId={props.user.id}
                                matches={matches.sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate)))}
                                limit={2}
                                updateMatch={(match: IMatch) => updateMatch(match)}
                                showMatchDetails={(match: IMatch) => {
                                    setSelectedMatch(match);
                                    setIsDetailsVisible(true);
                                }}
                            />
                        </Box>
                        <Box>
                            <TopscorersList limit={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box>
                            <SponsorsCard />
                        </Box>
                    </Grid>
                </>)}
            </Grid>
        </>
    );
}

export default DashboardStatistics;
