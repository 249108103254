import * as React from 'react';
import moment from 'moment';
import { Switch, FormGroup, FormControlLabel, styled, SwitchProps, useTheme, Button, Grid, Typography } from '@mui/material';
import { IMatch, IEvent, IMatchEnrollment } from 'entities';
import { EventService } from '../../services/eventService';

interface IProps {
    event: IEvent;
    userId?: number;
    membershipId?: number;
    showDetails: () => void;
    updateMatch?: (match: IMatch) => void;
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginLeft: 15,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const renderLabel = (isAttending: boolean, color: string): JSX.Element => {
    return (<span style={{ marginLeft: '10px', color: `${color}`, fontStyle: 'italic' }}>
        <b>{isAttending ? "Aanwezig" : "Afwezig"}</b>
    </span>);
}

const renderLabelPartner = (isAttending: boolean, color: string, disabled: boolean = false): JSX.Element => {
    return (<span style={{ marginLeft: '10px', color: `${disabled ? '#ccc' : color}`, fontStyle: 'italic' }}>
        <b>{isAttending ? "Met partner" : "Zonder partner"}</b>
    </span>);
}

const EventActions2: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isAttending, setIsAttending] = React.useState<boolean | null>(null);
    const [isAttendingPartner, setIsAttendingPartner] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        if (props.userId == null) {
            return;
        }

        const userEnrollment = props.event.enrollments.find(e => e.user.id === props.userId);
        if (userEnrollment === null) {
            setIsAttending(false);
            setIsAttendingPartner(false);
            return;
        }
        setIsAttending(userEnrollment?.status === "Aanwezig");
        setIsAttendingPartner(userEnrollment?.isAttendingPartner ?? false);

    }, [props.event])

    const onUpdate = (isAttending: boolean, isAttendingPartner: boolean) => {
        //if (props.updateMatch == null || props.userId == null) {
        //    return;
        //}

        if (props.userId == null) {
            return;
        }

        const service = new EventService()

        service.updateEnrollment(props.userId, props.event.id, isAttending, isAttendingPartner).then(data => {
            const event = props.event;
            const enrollmentIndex = props.event.enrollments.findIndex(e => e.user.id === props.userId);

            if (enrollmentIndex <= -1) {
                event.enrollments.push(data);
            } else {
                event.enrollments[enrollmentIndex] = data;
            }
        }).catch(err => {

        });

        setIsAttending(isAttending);
        setIsAttendingPartner(isAttendingPartner);
    }

    const currentDate = moment();
    const isActive = moment(props.event.matchDate).isSameOrAfter(currentDate, 'days');
    const isExpired = moment(props.event.matchDate).isSameOrBefore(currentDate, 'days');
    return (<>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                {(isActive && isAttending != null) && (
                    <FormGroup aria-disabled={true}>
                        <FormControlLabel
                            control={isExpired ? <></> : <IOSSwitch disabled={isExpired} color={isAttending ? "success" : "error"} checked={isAttending} onChange={() => onUpdate(!isAttending, isAttendingPartner ?? false)} name="Aangemeld" />}
                            label={renderLabel(isAttending, isAttending ? theme.colors.success.main : theme.colors.error.main)} />
                    </FormGroup>
                )}
                {(!isActive && isAttending != null) && (
                        <Typography variant="body1" color="text.secondary">
                            <div>{renderLabel(isAttending, isAttending ? theme.colors.success.main : theme.colors.error.main)}</div>
                        </Typography>
                )}

                {(isActive && isAttendingPartner != null) && (
                    <FormGroup>
                        <FormControlLabel
                            style={{ marginTop: '5px' }}
                            disabled={!isAttending}
                            control={isExpired  ? <></> : <IOSSwitch disabled={isExpired} color={isAttendingPartner ? "success" : "error"} checked={isAttendingPartner} onChange={() => onUpdate(isAttending ?? false, !isAttendingPartner)} name="Aangemeld" />}
                            label={renderLabelPartner(isAttendingPartner, isAttendingPartner ? theme.colors.success.main : theme.colors.error.main, !isAttending)} />
                    </FormGroup>
                )}
            </Grid>
            <Grid item xs={4}>
                <Button sx={{ marginInlineEnd: 1, float: "right" }}  variant="outlined" size="small" onClick={() => props.showDetails()}>
                    meer info
                </Button>
            </Grid>
        </Grid>
    </>);
}

export default EventActions2;
