import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import StadiumIcon from '@mui/icons-material/Stadium';
import Typography from '@mui/material/Typography';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import moment from 'moment';

const AccountableTimeline: React.FC = () => {
    function createData(
        type: string,
        name: string,
        date: string,
    ) {
        return { type, name, date };
    }

    const rows = [
        createData('BEKER', 'Tom', '3-9-2023'),
        createData('BEKER', 'Alcide', '10-9-2023'),
        createData('BEKER', 'Sjors', '17-9-2023'),
        createData('COMPETITIE', 'Mickey', '24-09-2023'),
        createData('COMPETITIE', 'Gökay', '1-10-2023'),
        createData('COMPETITIE', 'Steven', '8-10-2023'),
        createData('COMPETITIE', 'Yordi', '15-10-2023'),
        createData('BEKER / INHAAL', 'William', '22-10-2023'),
        createData('COMPETITIE', 'Eddy', '29-10-2023'),
        createData('COMPETITIE', 'David', '5-11-2023'),
        createData('COMPETITIE', 'Roy Horsten', '12-11-2023'),
        createData('BEKER / INHAAL', 'Selcuk', '19-11-2023'),
        createData('COMPETITIE', 'Sief', '26-11-2023'),
        createData('COMPETITIE', 'William de Jong', '3-12-2023'),
        createData('COMPETITIE', 'Thomas', '28-1-2024'),
        createData('COMPETITIE', 'Niels', '4-2-2024'),
        createData('COMPETITIE', 'Richard', '25-2-2024'),
        createData('COMPETITIE', 'Bram', '3-3-2024'),
        createData('COMPETITIE', 'Jason', '10-3-2024'),
        createData('COMPETITIE', 'Tom', '17-3-2024'),
        createData('COMPETITIE', 'Alcide', '24-3-2024'),
        createData('COMPETITIE', 'Sjors', '7-4-2024'),
        createData('COMPETITIE', 'Mickey', '14-4-2024'),
        createData('COMPETITIE', 'Steven', '20-5-2024'),
    ];

    return (
        <Timeline position="right" nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
            {rows.map(item => (
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        {item.type}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        {item.type === "BEKER" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "BEKER / INHAAL" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "COMPETITIE" && (
                            <TimelineDot color="primary" sx={{ marginLeft: '10px' }}>
                                <StadiumIcon />
                            </TimelineDot>
                        )}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            {item.name}
                        </Typography>
                        <Typography>{moment(item.date, 'DD-MM-YYYY').format("LL")}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}

export default AccountableTimeline;