import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import Geocode from "react-geocode";
import { IMatch, ITeam } from 'entities';
import { MatchService } from 'services/matchService';
import moment from 'moment';
import { ImagePath } from 'images';
import { TeamService } from 'services/teamService';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Coords } from 'google-map-react';

interface IProps {
    selectedMatch: IMatch | null;
    isOpen: boolean;
    onSave: (user: IMatch) => void;
    onClose: () => void;
}

const initDraftMatch: Partial<IMatch> = {
}

const EditMatchDialog: React.FC<IProps> = (props) => {
    const [isMobile, setIsMobile] = React.useState(false);
    const [editMatch, setEditMatch] = React.useState<IMatch | Partial<IMatch>>(props.selectedMatch ?? initDraftMatch);
    const [teams, setTeams] = React.useState<ITeam[]>([]);
    const [matchLocation, setMatchLocation] = React.useState<Coords | null>(null);
    const scoreOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const matchService = new MatchService();
    const teamService = new TeamService();

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, []);
    React.useEffect(() => {
        teamService.getTeams().then((data) => {
            setTeams(data.sort((a, b) => a.name.localeCompare(b.name)) || []);
        })

        const newDate = moment(editMatch.matchDate);
        const mDate = moment(editMatch.matchDate);
        newDate.hours(mDate.hours());
        newDate.seconds(mDate.seconds());

        const match = { ...editMatch, matchDate: newDate.toDate() };
        setEditMatch(match);
    }, [])

    const onChangeLocation = (value: string): void => {
        const match = { ...editMatch, location: value };
        setEditMatch(match);
    }

    const onChangeGoals = (value: number): void => {
        const match = { ...editMatch, teamGoals: parseInt(value.toString()) };
        setEditMatch(match);
    }

    const onChangeOpponnentGoals = (value: number): void => {
        const match = { ...editMatch, opponentTeamGoals: parseInt(value.toString()) };
        setEditMatch(match);
    }

    const onChangeTime = (value: string): void => {
        const newDate = moment(editMatch.matchDate);
        newDate.hours(parseInt(value.split(':')[0]));
        newDate.minutes(parseInt(value.split(':')[1]));

        const match = { ...editMatch, matchDate: newDate.toDate() };
        setEditMatch(match);
    }

    const onChangeDate = (value: string): void => {
        const newDate = moment(value);

        const mDate = moment(editMatch.matchDate);
        newDate.hours(mDate.hours());
        newDate.seconds(mDate.seconds());

        const match = { ...editMatch, matchDate: newDate.toDate() };
        setEditMatch(match);
    }

    const onSave = (): void => {
        if (editMatch.id == null) {
            if (!canSaveMatch) {
                return;
            }
            // Create the match.
            matchService.createMatch(editMatch).then((newMatch) => {
                if (newMatch == null) {
                    return;
                }
                props.onSave(newMatch)
            });
        } else {
            // Save the edited match.
            matchService.updateMatch(editMatch.id, editMatch as IMatch);
            props.onSave(editMatch as IMatch);
        }
    }

    const onTeamChange = (value: number, type: 'home' | 'away'): void => {
        let match = editMatch;
        const selectedTeam = teams.find(t => t.id === value);
        debugger;
        if (selectedTeam == null) {
            return;
        }

        if (type === "home") {
            match = { ...editMatch, team: selectedTeam };
        } else if (type == "away") {
            match = { ...editMatch, opponentTeam: selectedTeam };
        }
        setEditMatch(match);
    }

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 30, height: 30, marginRight: '15px', display: 'inline-block;', verticalAlign: 'middle' }}
        />
            {team.name}
        </>);
    }

    React.useEffect(() => {
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey("AIzaSyB1fbHrux9w5e0jbDUifWalk_JvjvzoOUM");

        // Get latitude & longitude from address.
        Geocode.fromAddress(editMatch.location || "Tilburg").then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setMatchLocation({
                    lat, lng
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [editMatch.location]);

    const canSaveMatch = editMatch.team?.id != null && editMatch.opponentTeam?.id != null
    return (<>
        <Box>
            <Dialog open={props.isOpen} onClose={props.onClose} sx={{
                "& .MuiDialog-container": {
                    justifyContent: "center",
                    alignItems: "flex-start"
                }
            }} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ paddingBottom: 0 }}>
                    <h4>Wedstrijd gegevens</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vul hieronder uw wijzigingen in.
                    </DialogContentText>

                    <FormControl fullWidth sx={{ marginTop: '25px' }}>
                        <InputLabel id="demo-simple-select-label">Thuis</InputLabel>
                        <Select
                            native={isMobile}
                            value={editMatch.team?.id}
                            label="Team Thuis"
                            placeholder="Selecteer team"
                            onChange={(e) => onTeamChange(Number(e.target.value), 'home')}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                        >
                            {teams.map(team => (
                                isMobile ? (
                                    <option key={team.id} value={team.id}>{renderTeam(team)}</option>
                                ) : (
                                    <MenuItem key={team.id} value={team.id}>{renderTeam(team)}</MenuItem>
                                )
                            ))}
                            {/*{teams.map((team) => <MenuItem key={team.id} value={team.id}>{renderTeam(team)}</MenuItem>)}*/}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '25px' }}>
                        <InputLabel id="demo-simple-select-label">Uit</InputLabel>
                        <Select
                            native={isMobile}
                            value={editMatch.opponentTeam?.id}
                            label="Team Uit"
                            placeholder="Selecteer team"
                            onChange={(e) => onTeamChange(Number(e.target.value), 'away')}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                        >
                            {teams.map(team => (
                                isMobile ? (
                                    <option key={team.id} value={team.id}>{renderTeam(team)}</option>
                                ) : (
                                    <MenuItem key={team.id} value={team.id}>{renderTeam(team)}</MenuItem>
                                )
                            ))}
                            {/*{teams.map((team) => <MenuItem key={team.id} value={team.id}>{renderTeam(team)}</MenuItem>)}*/}
                        </Select>
                    </FormControl>
                    <TextField
                        style={{ marginRight: '20px' }}
                        margin="normal"
                        id="date"
                        label="Datum"
                        type="date"
                        variant="standard"
                        value={editMatch.matchDate ? moment(editMatch.matchDate).format("YYYY-MM-DD") : null}
                        onChange={(e) => onChangeDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="normal"
                        id="time"
                        label="Tijd"
                        type="time"
                        variant="standard"
                        value={editMatch.matchDate ? moment(editMatch.matchDate).format("HH:mm") : null}
                        onChange={(e) => onChangeTime(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        id="adres"
                        label="Adres"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editMatch.location ? editMatch.location : " "}
                        onChange={(e) => onChangeLocation(e.target.value)}
                    />
                    {matchLocation != null && (<>
                        <LoadScript
                            googleMapsApiKey="AIzaSyDISt9DVtpHshhlXYGbAxnHmZaTbJHDhdY"
                        >
                            <GoogleMap
                                mapContainerStyle={{
                                    height: "200px",
                                    width: "100%"
                                }}
                                center={matchLocation}
                                zoom={15}
                            >
                                <Marker
                                    position={matchLocation}
                                />
                                <></>
                            </GoogleMap>
                        </LoadScript>
                    </>)}

                    <Box sx={{ width: '100%', marginTop: '10px' }}>
                        <Grid container rowSpacing={1}>
                            <Grid xs={5} style={{ marginRight: '20px' }}>
                                {/*<TextField*/}
                                {/*    margin="normal"*/}
                                {/*    id="name"*/}
                                {/*    label="Score Thuis"*/}
                                {/*    type="number"*/}
                                {/*    variant="standard"*/}
                                {/*    value={editMatch.teamGoals}*/}
                                {/*    onChange={(e) => onChangeGoals(Number(e.target.value))}*/}
                                {/*/>*/}
                                <FormControl fullWidth sx={{ marginTop: '25px' }}>
                                    <InputLabel id="demo-simple-select-label">Score Thuis</InputLabel>
                                    <Select
                                        native={isMobile}
                                        label="Score Thuis"
                                        value={editMatch.teamGoals}
                                        onChange={(e) => onChangeGoals(Number(e.target.value))}
                                    >
                                        {scoreOptions.map(option => (
                                            isMobile ? (
                                                <option key={option} value={option}>{option}</option>
                                            ) : (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            )
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={5}>
                                {/*<TextField*/}
                                {/*    margin="normal"*/}
                                {/*    id="name"*/}
                                {/*    label="Score Uit"*/}
                                {/*    type="number"*/}
                                {/*    variant="standard"*/}
                                {/*    value={editMatch.opponentTeamGoals}*/}
                                {/*    onChange={(e) => onChangeOpponnentGoals(Number(e.target.value))}*/}
                                {/*/>*/}
                                <FormControl fullWidth sx={{ marginTop: '25px' }}>
                                    <InputLabel id="demo-simple-select-label">Score Uit</InputLabel>
                                    <Select
                                        native={isMobile}
                                        label="Score Uit"
                                        value={editMatch.opponentTeamGoals}
                                        onChange={(e) => onChangeOpponnentGoals(Number(e.target.value))}
                                    >
                                        {scoreOptions.map(option => (
                                            isMobile ? (
                                                <option key={option} value={option}>{option}</option>
                                            ) : (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            )
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ marginBottom: '15px' }}>
                    <Button color="secondary" onClick={props.onClose}>Terug</Button>
                    <Button variant="contained" disabled={!canSaveMatch} onClick={onSave}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </Box>

    </>);
}

export default EditMatchDialog;
