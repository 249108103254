import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography, Card, Avatar, CardMedia, Button, IconButton, styled } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { UserService } from 'services/userService';
import { IUser } from 'entities';

// ICONS
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { BlobServiceClient } from '@azure/storage-blob';

const Input = styled('input')({
    display: 'none'
});

const AvatarWrapper = styled(Card)(
    ({ theme }) => `
    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
    ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const CardCover = styled(Card)(
    ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

interface ProfileCoverProps {
    user: IUser;
    isViewOnly: boolean;
}

interface IProfileAvatar {
    url: string;
}

const ProfileCover: React.FC<ProfileCoverProps> = (props) => {
    const { reloadAuthUser } = useAuth();
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();

    const blobSasUrl = 'https://mimavevablob.blob.core.windows.net/mimaveva?sp=racwl&st=2022-12-28T10:49:19Z&se=2023-12-28T18:49:19Z&spr=https&sv=2021-06-08&sr=c&sig=zc1gWewG1v5baN9CzKzrH2mKFjKRXQrclqNH4ROoZtA%3D';
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    const containerName = 'mimaveva';
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const [profileAvatarUrl, setProfileAvatarUrl] = React.useState<IProfileAvatar | null>(null);

    const userService = new UserService();

    const handleBack = (): void => {
        return navigate(-1);
    };

    const handleCapture = async ({ target }: any) => {
        try {
            const promises = [];
            const blobName = `avatar-${props.user.email}-${moment().format("YYYY-MM-DD-HH:mm:ss")}`;
            for (const file of target.files) {
                const blobService = containerClient.getBlockBlobClient(blobName);
                promises.push(blobService.uploadBrowserData(file));
            }
            await Promise.all(promises).then(() => {
                const newProfileImage = containerClient.getBlockBlobClient(blobName);
                setProfileAvatarUrl(newProfileImage);

                // Update user
                userService.updateUserAvatar(props.user.guid, newProfileImage.url);
                reloadAuthUser();
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box display="flex" mb={3}>
                <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 0 }}
                    variant="contained"
                    endIcon={<KeyboardReturnIcon />}
                    onClick={handleBack}
                >Terug
                </Button>
            </Box>
            <CardCover>
                <CardMedia image="https://images.squarespace-cdn.com/content/v1/5c9b8f380cf57d0333371716/1596721995691-P91W55BSRQJQ0S0NX2WZ/SoccerCamp_WebBanner.jpg?format=2500w" />
            </CardCover>
            <AvatarWrapper>
                <Avatar variant="rounded" alt={props.user.username} src={profileAvatarUrl ? profileAvatarUrl.url : props.user.avatar} />
                <ButtonUploadWrapper>
                    <Input
                        accept="image/*"
                        id="icon-button-file"
                        name="icon-button-file"
                        type="file"
                        onChange={handleCapture}
                    />
                    {!props.isViewOnly && (
                        <label htmlFor="icon-button-file">
                            <IconButton component="span" color="primary">
                                <UploadTwoToneIcon />
                            </IconButton>
                        </label>
                    )}
                </ButtonUploadWrapper>
            </AvatarWrapper>
            <Box py={2} pl={2}>
                <Typography gutterBottom variant="h4">
                    {props.user.username}
                </Typography>
                <Typography variant="subtitle2">{props.user.role} | {props.user.email}</Typography>
            </Box>
        </>
    );
};

ProfileCover.propTypes = {
    // @ts-ignore
    user: PropTypes.object.isRequired
};

export default ProfileCover;
